<template>
    <div v-if="checkAccess">
        <slot></slot>
    </div>
    <div v-else-if="!silent">
        <div>{{$t('You do not have the required access to view this content')}}</div>
    </div>
</template>

<script>
import {hasAccess} from './CheckRoles';

export default {
    name: "CheckAccess",
    props: {
        roles: Array,
        mode: {
            type: String,
            default: 'any'
        },
        client: {
            type: String,
            default: ''
        },
        silent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        checkAccess() {
            return hasAccess(
                this.client ? this.$auth.user.roles[this.client]?.roles : this.$auth.user.allRoles,
                this.roles,
                this.mode
            );
        }
    }
}
</script>

<style lang="scss" scoped>
</style>