<template>
    <FullLoader v-if="loading" />
	<div v-else>
        <Toast />
        <h2>{{$t("User Preferences")}}</h2>
        <div class="p-grid p-mt-2">
            <div class="p-col-12 p-md-3">
                <div class="section-title">{{$t('Language')}}</div>
                <div>{{$t("This controls the displayed language in this interface and in the notifications you receive.")}}</div>
            </div>
            <div class="p-col-12 p-md-9">
                <div class="p-fluid">
                    <div class="p-field">
                        <label for="language">{{$t('Change your language preference')}}</label>
                        <Dropdown class="o-field" id="language" v-model="settings.user.locale" @change="updateSettings()" :options="Object.keys($i18n.messages)"/>
                    </div>
                </div>
            </div>
        </div>        
        <check-access-basic :roles="['dashboard-role-administrator', 'dashboard-role-manager', 'dashboard-access']" client="unicef-dashboard" >
            <div class="p-grid p-mt-3">
                <div class="p-col-12 p-md-3">
                    <div class="section-title">2FA</div>
                    <div>{{$t("Controls if the system will send you a separate message when logging-in to confirm the login attempt is legitimate.")}}</div>
                </div>
                <div class="p-col-12 p-md-9">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="lastname2">{{$t('Two Factor Authentication')}}</label>
                            <SelectButton class="o-select" v-model="settings.user.twofa" :options="twoFAOptions" optionValue="value" dataKey="value" @input="updateSettings()" >
                                <template #option="slotProps">
                                    <i :class="slotProps.option.icon"></i>
                                </template>
                            </SelectButton>
                        </div>
                    </div>
                </div>
            </div>
        </check-access-basic>
        <check-access-basic :roles="['dashboard-role-administrator', 'dashboard-role-manager']" client="unicef-dashboard" >
            <div class="p-grid p-mt-3">
                <div class="p-col-12 p-md-3">
                    <div class="section-title">{{$t("Notifications")}}</div>
                    <div>{{$t("Controls how you will be notified. This includes 2FA messages.")}}</div>
                </div>
                <div class="p-col-12 p-md-9">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="notification">{{$t('Notification Preference')}}</label>
                            <Dropdown class="o-field" id="notification" v-model="settings.user.notification" @change="updateSettings()" :options="notificationOptions"/>
                        </div>
                    </div>
                </div>
            </div>
        </check-access-basic>
        <check-access-basic v-if="settings.user.notification === 'SMS'" :roles="['dashboard-role-administrator', 'dashboard-role-manager', 'dashboard-access']" client="unicef-dashboard" >
            <div class="p-grid p-mt-3">
                <div class="p-col-12 p-md-3">
                    <div class="section-title">SMS</div>
                    <div>{{$t("Configure your mobile phone number to enable SMS notifications.")}}</div>
                </div>
                <div class="p-col-12 p-md-9">
                    <div class="p-grid">
                        <div class="p-md-12">
                            <label :class="invalidClass()" for="smsnumber">{{$t('Mobile Phone Number')}}</label>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <country-code v-model="settings.user.country" class="p-d-flex o-field p-ai-stretch p-jc-center" />
                            </div>
                        </div>
                        <div class="p-col-12 p-md-9">
                            <div class="p-fluid">
                                <div class="p-field">                       
                                    <div class="p-inputgroup">
                                        <InputText class="o-field" id="smsnumber" type="text" v-model="settings.user.smsNumber" :class="invalidClass()" />
                                        <Button v-if="'+' + settings.user.country.countryCode + settings.user.smsNumber !== settings.user.confirmedSms || !settings.user.smsValid" class="p-button-info" :label="$t('Check')" :disabled="!settings.user.smsNumber" @click="doConfirmSMS()"/>
                                    </div>
                                    <small v-if="'+' + settings.user.country.countryCode + settings.user.smsNumber !== settings.user.confirmedSms || !settings.user.smsValid" class="p-invalid">{{$t('Enter and confirm your mobile phone number')}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </check-access-basic>
        <Dialog :header="$t('Confirm Mobile Phone Number')" :visible.sync="displaySMSDialog" :dismissableMask="false" :style="{width: '600px'}" :modal="true" :closable="true">
            {{$t('A code was sent to the provided number. Please input the 6 digit code in the field bellow')}}
            <div class="p-field">
                <div class="p-inputgroup">
                    <Button class="p-button-info" :label="$t('Ok')" @click="validateSMS()" :disabled="validationCode" />
                    <InputText id="smsnumber" type="text" v-model="validationCode" />
                </div>            
            </div>
        </Dialog>
	</div>
</template>

<script>
import CheckAccessBasic from '../auth/CheckAccessBasic.vue';
import ApiService from '../service/ApiService';
import CountryCode from "../components/CountryCode.vue";
import FullLoader from "../components/FullLoader.vue";

export default {
    name: "MyAccount",
	props: {
	},
	data() {
		return {
            loading: true,
            displaySMSDialog: false,
            code: "",
            validationCode: "",
            settings: {
                user: {
                    locale: this.$i18n.locale,
                    sub: this.$auth.user.sub,
                    notification: 'Email',
                    twofa: false,
                    smsNumber: '',
                    smsValid: false,
                    confirmedSms: '',
                    country: {},
                }
            },
            notificationOptions: [
                'Email',
                'SMS'
            ],
            twoFAOptions: [
                {icon: 'pi pi-times', value: false},
                {icon: 'pi pi-check', value: true}
            ]
		};
	},
    created(){
        this.api = new ApiService();
        this.api.getFromEp('user', {filters:JSON.stringify([{field: 'sub', value: this.$auth.user.sub}])}).then((users) => {
            if (users.length) {
                this.settings = users[0];
            }
            this.loading = false;
        });
    },
	methods: {
        setLocale() {
            localStorage.locale = this.settings.user.locale;
            this.$i18n.locale = this.settings.user.locale;
        },
        updateSettings(){
            if (this.settings.user.notification === 'Email' || (this.settings.user.notification === 'SMS' && this.settings.user.smsValid)) {
                this.setLocale();
                this.api.postToEp('user', this.settings).then(() => {
                    this.$toast.add({severity:'success', summary: this.$t('Success'), detail:this.$t('Preferences updated successfully'), life: 3000});
                })
            }
        },
        invalidClass() {
            return ('+' + this.settings.user.country.countryCode + this.settings.user.smsNumber === this.settings.user.confirmedSms && this.settings.user.smsValid) ? '':'p-invalid';
        },
        doConfirmSMS() {
            this.code = "";
            for (let i=0; i<6; i++) {
                this.code += Math.random().toString()[3];
            }
            this.api.postToEp('sms', 
            {
                "phoneNumber": '+' + this.settings.user.country.countryCode + this.settings.user.smsNumber,
                "message": this.$t('Your V-Trace platform verification code : ') + this.code
            }).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t('Error'), detail:this.$t('There was an error sending the code. Please try again later.'), life: 5000});
            })
            this.displaySMSDialog = true;
        },
        validateSMS() {
            if (this.code === this.validationCode) {
                this.settings.user.smsValid = true;
                this.settings.user.confirmedSms = '+' + this.settings.user.country.countryCode + this.settings.user.smsNumber;
                this.updateSettings();
                this.displaySMSDialog = false;
            }
        }
	},
	components: {
        CheckAccessBasic,
        CountryCode,
        FullLoader
	},
};
</script>

<style scoped>
.section-title {
    font-size: 24px;
}
.o-select {
    width: 90px
}
.p-invalid {
    color: #FF0000
}
.o-field {
    height: 37px;
}
/deep/ .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
}
</style>
